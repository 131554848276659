@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.blog-main .feature-links {
  border-bottom: 1px solid #ccc; }
  .blog-main .feature-links > .experience-region {
    display: flex;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .blog-main .feature-links > .experience-region {
        justify-content: flex-start;
        overflow-x: auto;
        padding-bottom: 10px; } }
    .blog-main .feature-links > .experience-region .nav-link {
      color: #272728;
      font-weight: 400; }
      @media (max-width: 991.98px) {
        .blog-main .feature-links > .experience-region .nav-link {
          padding: 0 8px; } }
      .blog-main .feature-links > .experience-region .nav-link .nav-title {
        font-size: 18px;
        font-family: "Suisse Book", sans-serif;
        line-height: normal;
        border-bottom: 1px solid transparent;
        white-space: nowrap; }
        @media (max-width: 543.98px) {
          .blog-main .feature-links > .experience-region .nav-link .nav-title {
            font-size: 14px;
            margin-bottom: 0; } }
      .blog-main .feature-links > .experience-region .nav-link:hover {
        color: #272728; }
        .blog-main .feature-links > .experience-region .nav-link:hover .nav-title {
          border-color: #272728; }

@media (min-width: 769px) {
  .blog-main .experience-component .short-container {
    max-width: 70%;
    margin: 0 auto; } }

.blog-main .experience-component .short-container .region {
  padding-left: 0; }

.blog-main .experience-component .short-container .ITC-text-underneath {
  padding-left: 0; }
  @media (min-width: 544px) {
    .blog-main .experience-component .short-container .ITC-text-underneath {
      padding-left: 40px; } }

@media (max-width: 543.98px) {
  .blog-main .experience-component .short-container .image-component {
    padding: 30px 0; } }

@media (min-width: 544px) {
  .blog-main .experience-component .short-container .image-component .first {
    width: 30%;
    padding-top: 0; } }

@media (min-width: 544px) {
  .blog-main .experience-component .short-container .image-component .first img {
    height: 100%; } }

@media (min-width: 769px) {
  .blog-main .experience-component .short-container .image-component .left.second {
    width: 70%;
    padding: 100px 0;
    margin-top: 0; } }

.blog-main .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0; }
  .blog-main .pagination__item {
    margin-right: 20px;
    font-size: 18px;
    padding: 0 5px;
    border-bottom: 1px solid transparent; }
    .blog-main .pagination__item:first-child, .blog-main .pagination__item:last-child {
      border-bottom: 0; }
    .blog-main .pagination__item__link.arrow-next, .blog-main .pagination__item__link.arrow-prev {
      border: 1px solid #000;
      border-radius: 50%;
      font-size: 32px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .blog-main .pagination__item__link.arrow-next:hover, .blog-main .pagination__item__link.arrow-prev:hover {
        text-decoration: none; }
    .blog-main .pagination__item__link.disabled {
      pointer-events: none; }
    .blog-main .pagination__item__link:hover {
      text-decoration: none; }
    .blog-main .pagination__item:last-child {
      margin-left: 20px;
      margin-right: 0; }
    .blog-main .pagination__item:hover {
      border-color: #000; }
  .blog-main .pagination__select {
    border-color: #000;
    color: #272728;
    margin: 0 10px;
    padding: 8px;
    width: auto; }
  .blog-main .pagination p {
    margin-bottom: 0; }

.blog-main .blog-card {
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative; }
  .blog-main .blog-card::before {
    background-color: #f7f5f2;
    box-shadow: var(--skin-box-shadow);
    content: '';
    height: calc(100% + 20px);
    left: -10px;
    opacity: 0;
    position: absolute;
    top: -10px;
    transition: all 0.15s ease-in-out;
    visibility: hidden;
    width: calc(100% + 20px);
    z-index: -1; }
  .blog-main .blog-card:hover::before {
    opacity: 1;
    visibility: visible; }
  .blog-main .blog-card__folder-name {
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    display: inline-block;
    letter-spacing: 1px; }
  .blog-main .blog-card__description {
    font-size: 16px; }
  .blog-main .blog-card__content-main {
    padding: 0 15px 40px; }
  .blog-main .blog-card .photo-tile-figure {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 100%; }
    @media (min-width: 769px) {
      .blog-main .blog-card .photo-tile-figure {
        min-height: 350px; } }
    .blog-main .blog-card .photo-tile-figure img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .blog-main .blog-card__content-main__tags {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    margin-right: 5px; }
    .blog-main .blog-card__content-main__tags::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.0625rem;
      background-color: var(--skin-primary-color);
      transition: width 0.3s ease-in;
      content: '';
      z-index: 0; }
    .blog-main .blog-card__content-main__tags:hover {
      color: var(--skin-secondary-color); }
      .blog-main .blog-card__content-main__tags:hover::before {
        width: 0; }

.blog-main .author {
  display: flex;
  padding: 0 15px; }
  .blog-main .author__img-holder {
    max-width: 100px;
    padding-right: 10px; }
  .blog-main .author__content-main {
    padding-left: 15px; }
    .blog-main .author__content-main__heading {
      font-size: 24px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 15px; }

.blog-main .back-link {
  font-size: 13px;
  display: inline-block;
  margin-bottom: 40px; }
  .blog-main .back-link span {
    font-size: 20px;
    margin-right: 8px; }

.blog-main .blog-post-header {
  margin-bottom: 80px; }
  .blog-main .blog-post-header__content {
    margin-top: 20px; }
    @media (min-width: 769px) {
      .blog-main .blog-post-header__content {
        padding-left: 75px;
        margin-top: 0; } }
    .blog-main .blog-post-header__content__folder {
      display: inline-block;
      border-bottom: 1px solid #000; }
      .blog-main .blog-post-header__content__folder:hover {
        text-decoration: none; }

@media (min-width: 992px) {
  .blog-main .main-article-links {
    position: relative;
    transition: all 0.3s;
    margin: 0; } }

@media (min-width: 992px) {
  .blog-main .main-article-links.fixed {
    position: fixed;
    top: 135px;
    min-width: 300px;
    max-width: 300px;
    background: #f7f5f2;
    z-index: 1; } }

.blog-main .main-article-links li {
  padding: 15px 0;
  border-top: 1px solid #ccc; }
  .blog-main .main-article-links li .anchor-link {
    text-align: left;
    display: block;
    font-size: 14px;
    font-weight: normal;
    padding-right: 30px; }
    .blog-main .main-article-links li .anchor-link::after {
      content: "\f105";
      font-size: 20px;
      font-family: 'FontAwesome';
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%); }
    .blog-main .main-article-links li .anchor-link:hover {
      color: #000; }

@media (max-width: 543.98px) {
  .blog-main .main-blog-detail .section-heading {
    padding: 0; } }

@media (max-width: 543.98px) {
  .blog-main .main-blog-detail .region {
    padding: 0; } }

.blog-main .main-blog-detail p a,
.blog-main .main-blog-detail li a {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  display: inline-block; }
  .blog-main .main-blog-detail p a::before,
  .blog-main .main-blog-detail li a::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.0625rem;
    background-color: var(--skin-primary-color);
    transition: width 0.3s ease-in;
    content: '';
    z-index: 0; }
  .blog-main .main-blog-detail p a:hover,
  .blog-main .main-blog-detail li a:hover {
    color: var(--skin-secondary-color); }
    .blog-main .main-blog-detail p a:hover::before,
    .blog-main .main-blog-detail li a:hover::before {
      width: 0; }

.blog-main .main-blog-detail .experience-component img {
  position: relative; }

.blog-main .faq-container {
  max-width: 100%;
  margin: 0 0 50px; }
  .blog-main .faq-container .faq-item-content ul {
    padding-left: 20px; }
    .blog-main .faq-container .faq-item-content ul li {
      font-size: 16px;
      margin-bottom: 20px; }
  .blog-main .faq-container .faq-item.active .title {
    padding: 14px 0 !important; }

.blog-main .faq-item-content ol {
  line-height: normal; }
